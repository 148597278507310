.research-modal {
    max-width: 825px;
    display: flex;
    color: #333333;
    flex-direction: column;
}

.research-modal .title-modal {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
}
.research-modal .sub-title-modal {
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    margin-top: 40px;
}

.research-modal .row-modal {
    display: flex;
    flex-direction: row;
}

.research-modal .row-modal.wrap {
    flex-wrap: wrap;
}

.research-modal .input-container {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    margin-top: 28px;
}


.research-modal .near-block:first-child {
    justify-content: flex-start;
    flex-grow: 0;
}

.research-modal .succes-block {
    display: flex;
    align-items: center
}

.research-modal .success-text{
    margin-left: 13px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #AAA9A9
}

.research-modal .success-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C4.48622 0 0 4.48622 0 10C0 15.5138 4.48622 20 10 20C15.5138 20 20 15.5138 20 10C20 4.48622 15.5138 0 10 0ZM15.589 7.36842L9.19799 13.7093C8.82205 14.0852 8.22055 14.1103 7.81955 13.7343L4.43609 10.6516C4.03509 10.2757 4.01002 9.64912 4.3609 9.24812C4.73684 8.84712 5.36341 8.82205 5.76441 9.19799L8.44611 11.6541L14.1604 5.93985C14.5614 5.53885 15.188 5.53885 15.589 5.93985C15.99 6.34085 15.99 6.96742 15.589 7.36842Z' fill='%2362d7a5'/%3E%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
}

.research-modal .weigth-length-table {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
}
.research-modal .weigth-length-table .header div {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    color: #aaa9a9;
}
.research-modal .weigth-length-table .header div:first-child {
    min-width: 40px;
}
.research-modal .weigth-length-table-one {
    display: flex;
}
.research-modal .weigth-length-table-two {
    display: flex;
    flex-grow: 1;
    width: 33%; 
}
.research-modal .weigth-length-table .serial {
    display: flex;
    font-size: 20px;
    line-height: 24px;
    color: #aaa9a9;    
    justify-content: center;    
    align-items: flex-end;
    width: 40px;
}
.research-modal .weigth-length-table .input-container {
    margin: 0;
}
.research-modal .weigth-length-table .input-content {
    max-width: 100%;
}
.research-modal .weigth-length-table .input-content > input {
    min-width: 100%;
}

.research-modal .weigth-length-table .add-more {
    color: #4C69FF;
    cursor: pointer;
    margin-top: 20px;
}

.research-modal .weigth-length-table .add-more:hover{
    color: #9baaf8;
}