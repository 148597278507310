.sidebar-selector {
  position: relative;
  font-family: Arial;
  background: transparent;
  border: none;
  color: #ffffff;
  margin: 0 5% 0 20%;
  padding: 0;
  outline: none;
  height: 25px;
}

.sidebar-selector:focus {
  background-color: #ffffff;
  color: #333333;
  border: none;
  border-radius: 4px;
  width: 80%;
  margin: 0 4% 0 16%;
  box-shadow: 0px 0px 20px rgb(96 165 230 / 25%);
}
