body {
  overflow: hidden;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
.single-item-after {
  content: "";
  height: 18px;
  width: 18px;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.gridline {
  stroke: black;
  shape-rendering: crispEdges;
  stroke-opacity: 0.1;
}
.fixedHeader {
  position: fixed;
  top: 0;
  background-color: #fff;
}
.table {
  width: 99.9%;
}
.graphTooltip {
  position: absolute;
  min-width: 150px;
  line-height: 2em;
  padding: 11px 14px;
  font-size: 13px;
  color: #9e9e9e;
  pointer-events: none;
  background: #354656;
  border-radius: 4px;
  min-width: 200px;
}
.graphTooltip .cntr {
  justify-content: space-between;
}
.graphTooltip .first {
  border-bottom: 1px solid #e6e8ec;
  padding-bottom: 4px;
  margin-bottom: 4px;
}
.graphTooltip .accent {
  color: #fff;
}
.graphTooltip .dot {
  width: 6px;
  height: 6px;
  margin-right: 5px;
  border-radius: 50%;
  align-self: center;
}
.highliteRow {
  background-color: #d7f8ff !important;
}
.table-responsive {
  max-height: calc(100vh - 270px);
  margin-top: 10px;
}
.fixed-main-area {
  max-height: calc(100vh - 200px);
}
.table thead tr:nth-child(1) th {
  background: #f7f7f8;
  position: sticky;
  top: 0;
  z-index: 2;
  border-top: 0;
  border-bottom: 2px solid #e6e8ec;
  border-right: 2px solid #e6e8ec;
}
.table thead tr:nth-child(1) th:nth-child(1) {
  left: 0;
  z-index: 3;
  border-left: 0;
  border-radius: 0px 0 0 0;
}
.table thead tr:nth-child(1) th:last-child {
  border-right: 0;
  border-radius: 0 0px 0 0;
}
.table tbody tr > td:nth-of-type(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  border-left: 0;
}
.table-striped tbody tr > td {
  background-color: #fff;
}
.table-striped tbody tr:nth-of-type(even) td {
  background-color: #f7f7f8;
}
.table-striped tbody tr td:nth-child(1n):first-child {
  border-right: 2px solid #e6e8ec;
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #2ec0d1;
  border-color: #2ec0d1;
}
.item-title {
  max-width: 70%;
}
.heading-layout1 .btn-fill-sm {
  padding: 0;
  float: right;
}
.heading-layout1.start {
  justify-content: start;
  margin-bottom: 8px;
}
.fw-btn-fill {
  height: 46px;
  line-height: 15px;
  z-index: 7;
  background: linear-gradient(180deg, #2ec0d1 0%, #13acbe 113.04%);
  border: 1px solid #2ec0d1;
  box-sizing: border-box;
  border-radius: 8px;
  transition: none;
}
.fw-btn-fill:hover {
  background: #0b98a9;
}
.fw-text-fill {
  line-height: 33px;
  align-self: center;
}
.fixed-shadow {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000 7d;
  z-index: 1;
  top: 0%;
  left: 263px;
}
@media only screen and (max-width: 575px) {
  .sidebar-menu-one .sidebar-menu-content {
    margin-top: 68px;
 }
  .nav-bar-header-one {
    box-shadow: none;
    margin-left: -3.6rem;
 }
  .table thead tr:nth-child(1) th {
    position: relative;
 }
  .table tbody tr > td:nth-of-type(1) {
    position: relative;
 }
  .flex-fill-sm {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
 }
  .fixed-shadow {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #000 7d;
    z-index: 1;
    top: 0%;
    left: 0;
 }
}
.modellingTable th {
  text-align: center;
}
.modellingTable tbody tr td:nth-child(odd):not(:first-child) {
  background-color: #f4fcfc;
}
.modellingTable tbody tr td:nth-child(odd) {
  border-right: 2px solid #e6e8ec;
}
.modellingTable tbody tr td:nth-child(odd):not(:first-child) {
  text-align: right;
}
.modellingTable tbody tr td:nth-child(even):not(:first-child) {
  text-align: right;
}
.sidebar-color::-webkit-scrollbar, .dashboard-content-one::-webkit-scrollbar, .no-auth::-webkit-scrollbar {
  display: none;
}
.sidebar-color, .dashboard-content-one, .no-auth {
  -ms-overflow-style: none;
 /* IE and Edge */
  scrollbar-width: none;
 /* Firefox */
}
.export-to-excel {
  color: #1cb3c4;
  font-size: 15px;
  line-height: 15px;
  border: 1px solid #2ec0d1;
  border-radius: 8px;
  padding: 15px 18px 15px 29px !important;
}
.export-to-excel.excel-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.84375' width='15' height='15' rx='2' fill='%232EC0D1'/%3E%3Crect x='8.84375' y='2' width='5' height='2' fill='white'/%3E%3Crect x='8.84375' y='5' width='5' height='2' fill='white'/%3E%3Crect x='8.84375' y='8' width='5' height='2' fill='white'/%3E%3Crect x='8.84375' y='11' width='5' height='2' fill='white'/%3E%3Crect x='2.84375' y='11' width='5' height='2' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.23145 2.00003L2.2315 2H3.38613L4.82626 4.49439L6.2664 2H7.4211L5.40361 5.49439L7.32835 8.82812H6.17365L4.82626 6.49439L3.47888 8.82812H2.32418L4.24891 5.49439L2.23145 2.00003Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 6px 14px;
}
.export-to-excel.excel-icon:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 2C0 0.89543 0.895431 0 2 0H13C14.1046 0 15 0.895431 15 2V13C15 14.1046 14.1046 15 13 15H2C0.89543 15 0 14.1046 0 13V2ZM8 2H13V4H8V2ZM13 5H8V7H13V5ZM8 8H13V10H8V8ZM13 11H8V13H13V11ZM2 11H7V13H2V11ZM1.38775 2L1.3877 2.00003L3.40516 5.49439L1.48043 8.82812H2.63513L3.98251 6.49439L5.3299 8.82812H6.4846L4.55986 5.49439L6.57735 2H5.42265L3.98251 4.49439L2.54238 2H1.38775Z' fill='white'/%3E%3C/svg%3E%0A");
  background-color: #0b98a9;
  color: #fff;
}
.export-to-excel.image-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.84375 2.6875C0.84375 1.58293 1.73918 0.6875 2.84375 0.6875H11.4859C12.0163 0.6875 12.5251 0.898214 12.9001 1.27329L15.258 3.63113C15.633 4.0062 15.8438 4.51491 15.8438 5.04534V13.6875C15.8438 14.7921 14.9483 15.6875 13.8438 15.6875H2.84375C1.73918 15.6875 0.84375 14.7921 0.84375 13.6875V2.6875ZM9.84375 12.1875C9.84375 13.0159 9.17218 13.6875 8.34375 13.6875C7.51532 13.6875 6.84375 13.0159 6.84375 12.1875C6.84375 11.3591 7.51532 10.6875 8.34375 10.6875C9.17218 10.6875 9.84375 11.3591 9.84375 12.1875ZM3.84375 2.6875C3.29147 2.6875 2.84375 3.13522 2.84375 3.6875V5.6875C2.84375 6.23978 3.29147 6.6875 3.84375 6.6875H9.84375C10.396 6.6875 10.8438 6.23978 10.8438 5.6875V3.6875C10.8438 3.13522 10.396 2.6875 9.84375 2.6875H3.84375Z' fill='%232EC0D1'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 6px 14px;
}
.export-to-excel.image-icon:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 2C0 0.89543 0.89543 0 2 0H10.6422C11.1726 0 11.6813 0.210714 12.0564 0.585786L14.4142 2.94363C14.7893 3.3187 15 3.82741 15 4.35784V13C15 14.1046 14.1046 15 13 15H2C0.89543 15 0 14.1046 0 13V2ZM9 11.5C9 12.3284 8.32843 13 7.5 13C6.67157 13 6 12.3284 6 11.5C6 10.6716 6.67157 10 7.5 10C8.32843 10 9 10.6716 9 11.5ZM3 2C2.44772 2 2 2.44772 2 3V5C2 5.55228 2.44772 6 3 6H9C9.55229 6 10 5.55228 10 5V3C10 2.44772 9.55229 2 9 2H3Z' fill='white'/%3E%3C/svg%3E%0A");
  background-color: #0b98a9;
  color: #fff;
}
.close-window {
  background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='32.5918' y='33.0858' width='28' height='2' transform='rotate(-135 32.5918 33.0858)' fill='%23506482'/%3E%3Crect x='33.5918' y='13.0861' width='28' height='2' transform='rotate(135 33.5918 13.0861)' fill='%23506482'/%3E%3Cpath d='M0.5 8C0.5 3.85787 3.85786 0.5 8 0.5H38C42.1421 0.5 45.5 3.85786 45.5 8V38C45.5 42.1421 42.1421 45.5 38 45.5H8C3.85787 45.5 0.5 42.1421 0.5 38V8Z' stroke='%23506482'/%3E%3C/svg%3E%0A");
  width: 47px;
  height: 47px;
  background-repeat: no-repeat;
  background-size: cover;
}
.close-window:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='47' height='46' viewBox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.15625 7.99999C0.15625 3.58172 3.73797 -7.62939e-06 8.15625 -7.62939e-06H38.1562C42.5745 -7.62939e-06 46.1562 3.58171 46.1562 7.99999V38C46.1562 42.4183 42.5745 46 38.1562 46H8.15625C3.73797 46 0.15625 42.4183 0.15625 38V7.99999Z' fill='%230B98A9'/%3E%3Crect x='32.748' y='33.0858' width='28' height='2' transform='rotate(-135 32.748 33.0858)' fill='white'/%3E%3Crect x='33.748' y='13.0861' width='28' height='2' transform='rotate(135 33.748 13.0861)' fill='white'/%3E%3C/svg%3E%0A");
}
.bg-light-sea-green:hover {
  background-color: #0b98a9;
}
input[type=date] {
  border: 1px solid #c3c6cd;
  border-radius: 8px;
  padding: 7px 0px 7px 13px;
  background-image: url("data:image/svg+xml,%3Csvg width='29' height='34' viewBox='0 0 29 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0V34' stroke='%23C3C6CD'/%3E%3Crect x='14.5' y='10.5' width='14' height='14' rx='1.5' stroke='%23C3C6CD'/%3E%3Cpath d='M14 13C14 11.8954 14.8954 11 16 11H27C28.1046 11 29 11.8954 29 13V15H14V13Z' fill='%23C3C6CD'/%3E%3Crect x='26' y='8' width='4' height='2' transform='rotate(90 26 8)' fill='%23C3C6CD'/%3E%3Crect x='19' y='8' width='4' height='2' transform='rotate(90 19 8)' fill='%23C3C6CD'/%3E%3C/svg%3E%0A");
  background-position: 88% 50%;
  background-repeat: no-repeat;
  width: 82%;
}
select {
  border: 1px solid #c3c6cd;
  border-radius: 8px;
  padding: 13px 7px 13px 13px;
  background-repeat: no-repeat;
}
[type=date]::-webkit-inner-spin-button {
  display: none;
}
[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  padding: 7px 0px 7px 13px;
}
.no-auth {
  min-height: 100vh;
  padding: 25px;
  border: 1px solid #e6e8ec;
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 49px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 49px 0px rgba(0, 0, 0, 0.5);
  overflow: auto;
}

input[type=date] {
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5508 1.48438H14.8438V0.59375C14.8438 0.265852 14.5779 0 14.25 0C13.9221 0 13.6562 0.265852 13.6562 0.59375V1.48438H5.34375V0.59375C5.34375 0.265852 5.07794 0 4.75 0C4.42206 0 4.15625 0.265852 4.15625 0.59375V1.48438H2.44922C1.0987 1.48438 0 2.58307 0 3.93359V16.5508C0 17.9013 1.0987 19 2.44922 19H16.5508C17.9013 19 19 17.9013 19 16.5508V3.93359C19 2.58307 17.9013 1.48438 16.5508 1.48438ZM17.8125 16.5508C17.8125 17.2476 17.2476 17.8125 16.5508 17.8125H2.44922C1.75238 17.8125 1.1875 17.2476 1.1875 16.5508V6.7168C1.1875 6.61434 1.27059 6.53125 1.37305 6.53125H17.627C17.7294 6.53125 17.8125 6.61434 17.8125 6.7168V16.5508Z' fill='%23333333'/%3E%3C/svg%3E%0A");
  background-position: 93% 50%;
}