.select-container {
    position: relative;
    width: fit-content;
}
.select-container .title-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    width: fit-content;
    flex-grow: 1;
}

.select-container .title-container .drop-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 19 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.49995 10.9152C9.15943 10.9152 8.81895 10.7852 8.55934 10.5257L0.389769 2.35609C-0.129923 1.8364 -0.129923 0.993809 0.389769 0.474328C0.90925 -0.0451535 1.75167 -0.0451535 2.27141 0.474328L9.49995 7.70329L16.7285 0.47458C17.2482 -0.044901 18.0906 -0.044901 18.61 0.47458C19.1299 0.994061 19.1299 1.83665 18.61 2.35634L10.4406 10.526C10.1808 10.7855 9.84034 10.9152 9.49995 10.9152Z' fill='%234C69FF'/%3E%3C/svg%3E%0A");
    width: 19px;
    height: 11px;
    margin-left: 20px;
}
.select-container .dropdown-container {
    position: absolute;
    width: 480px;
    top: -40px;
    left: -40px;
    bottom: -40px;
    z-index: 10;
}
.select-container .dropdown {
    position: absolute;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgb(51 71 170 / 5%);
    border-radius: 2px;
    width: 400px;
    top: 40px;
    left: 40px;
    flex-direction: column;
}

.select-container .dropdown .dropdown-row {
    height: 40px;
    display: flex;
    border-bottom: 0.2px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    line-height: 20px;    
    align-items: center;
    width: 100%;
    padding: 0 21px;
    cursor: pointer;
}
.select-container .dropdown .dropdown-row:hover {
    background: rgba(235, 239, 254, 0.5);
    border-radius: 2px;
}


.select-container.small-select .title-container .drop-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns:inkscape='http://www.inkscape.org/namespaces/inkscape' xmlns:sodipodi='http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg' width='10' height='5' fill='none' version='1.1' id='svg19' sodipodi:docname='Vector.svg' inkscape:version='1.1 (c68e22c387, 2021-05-23)'%3E%3Cdefs id='defs23'/%3E%3Csodipodi:namedview id='namedview21' pagecolor='%23ffffff' bordercolor='%23666666' borderopacity='1.0' inkscape:pageshadow='2' inkscape:pageopacity='0.0' inkscape:pagecheckerboard='0' showgrid='false' inkscape:zoom='73.545455' inkscape:cx='8.5049444' inkscape:cy='5.5' inkscape:window-width='1920' inkscape:window-height='1017' inkscape:window-x='-8' inkscape:window-y='-8' inkscape:window-maximized='1' inkscape:current-layer='svg19'/%3E%3Cpath d='m 5.0055334,4.3489083 c -0.1760054,0 -0.3519935,-0.051679 -0.4861799,-0.1548591 L 0.29664344,0.94592177 c -0.26861924,-0.20662259 -0.26861924,-0.54162492 0,-0.74816294 0.26851074,-0.20653638 0.70394676,-0.20653638 0.97258536,0 L 5.0055334,3.0718823 8.7418441,0.19786098 c 0.2686266,-0.20653817 0.7040336,-0.20653817 0.972545,0 0.2687423,0.20653817 0.2687423,0.54153602 0,0.74815862 L 5.4917192,4.1941468 C 5.3574635,4.2973178 5.1814753,4.3489083 5.0055334,4.3489083 Z' fill='%234c69ff' id='path17' style='stroke-width:0.506659'/%3E%3C/svg%3E");
    width: 10px;
    height: 5px;
    margin-left: 20px;
}

.select-container.small-select .dropdown-container {    
    width: 280px;
}

.select-container.small-select .dropdown {    
    width: 200px;
}
.select-container.small-select .dropdown .dropdown-row {    
    height: 28px;
}
.select-container.full-width-select .title-container  {
    margin: 10px 0;
    margin-bottom: 14px;
    width: 100%;
}
.select-container.full-width-select .dropdown-container {
    top: -30px;
}


.select-container .active-title {
    display: flex;
    flex-grow: 1
}