.admin-page .input-column {
    justify-content: flex-end;
}

.admin-page .modal-table-container {
    margin-top: 40px;
}

.admin-page .add-user-container {
    margin: 10px;   
    width: 47%;
}