.table-header {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a9a9a9;
  margin-bottom: 15px;
}

.table-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 180px;
}
.table-body.big {
    max-height: 400px;
}

.table-row {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(51, 51, 51, 0.8);
  padding: 10px 0;
  align-items: center;
}
.table-row:hover {
  background-color: #EEF1FF;
}

.table-header .one,
.table-row .one {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  width: 10%;
  min-width: 135px;
}

.table-header .two,
.table-row .two {
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  justify-content: center;
  width: 20%;
  min-width: 135px;
}

.table-header .three,
.table-row .three {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  width: 20%;
  min-width: 135px;
}

.table-header .four,
.table-row .four {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  width: 10%;
  min-width: 135px;
}

.table-header .five,
.table-row .five {
  display: flex;
  flex-direction: row;
  flex-grow: 4;
  justify-content: center;
  width: 40%;
}

.table-row .button {
  background: #637cff;
  border-radius: 100px;
  padding: 5px 12px;
  color: #ffffff;
  font-weight: bold;
  min-width: 90px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-row .button.edit {
  background: #EEF1FF;
  color: #4C69FF;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
}

.table-row .button.edit:hover { 
  -webkit-box-shadow: 0px 4px 10px 0px rgb(238 241 255);  
  box-shadow: 0px 4px 10px 0px rgb(238 241 255);
}

.table-row .button.edit .edit-container {
  display: flex;  
}
.table-row .button.edit .editIcon {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M6.17554 1.67961L0.673399 7.18215C0.645718 7.20991 0.62573 7.24497 0.616212 7.28264L0.00635232 9.73049C-0.0118903 9.80418 0.00976291 9.88254 0.0635391 9.93632C0.104228 9.977 0.15967 9.99953 0.216381 9.99953C0.233751 9.99953 0.251518 9.99739 0.268809 9.99303L2.71666 9.38309C2.75481 9.37357 2.78947 9.35366 2.81715 9.32598L8.31977 3.82384L6.17554 1.67961Z' fill='%234C69FF'/%3E%3Cpath d='M9.68347 0.92935L9.07099 0.316873C8.66164 -0.0924762 7.94819 -0.0920797 7.53931 0.316873L6.78906 1.06712L8.93322 3.21127L9.68347 2.46102C9.88795 2.25662 10.0006 1.98457 10.0006 1.69522C10.0006 1.40588 9.88795 1.13383 9.68347 0.92935Z' fill='%234C69FF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='10' height='10' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  width: 10px;
  height: 10px;
  margin-right: 4px;
}

.table-no-scroller .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX, .table-no-scroller .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  display: none;
}