.card-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  margin-left: -10px;
  margin-right: -10px;
}

.card-container.extended-container {
  flex-wrap: nowrap;
}

.card-container-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.dashboard-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dashboard-row.start {
  justify-content: start;
  align-items: flex-end;
}

.dashboard-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  display: flex;
}

.dashboard-title-sub {
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
}

.ScrollbarsCustom-Wrapper {
  width: 100%;
  height: 100%;
}
.ScrollbarsCustom-Content {
  display: flex;
  flex-direction: column;
}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  background: #f7f5f5;
  user-select: none;
  width: 4px;
  height: calc(100% - 20px);
  top: 10px;
  right: 0px;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  cursor: pointer;
  border-radius: 4px;
  background: #ebe8e8;
  width: 100%;
  height: 0px;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  background: #f7f5f5;
  user-select: none;
  height: 4px;
  width: 100%;
  bottom: 0px;
  left: 0;
}
.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
  cursor: pointer;
  border-radius: 4px;
  background: #ebe8e8;
  height: 100%;
}


.page-container {
    display: flex;
    flex-direction: column;
}

.page-container .block-container {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}

.page-container .block-container:last-child {
  margin-bottom: 40px;
}

.card-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 35px;

}

.content-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: -10px;
    margin-right: -10px;
}

.content-container .card-block {
    min-height: 200px;
    overflow: hidden;
}