.modal-window {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    transition: all 0.3s;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    color: #506482;
}
.modal-window > div {
    min-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
    background: #fff;
    border-radius: 5px;
    max-height: 100vh;
}
.modal-window .modal-container { 
    padding: 40px 50px;
    display: flex;
}

.modal-window .modal-container .modal-body { 
    margin: 0;
    padding: 0;
}

.modal-window header {
    font-weight: bold;
}
.modal-window h1 {
    font-size: 150%;
    margin: 0 0 15px;
}
.modal-window h1.error {
    color: red;
}
.modal-window h1.warning {
    color: yellow;
}
.modal-window h1.info {
    color: gray;
}

.modal-close {
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: transparent;
    
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8811 12.5008L24.714 1.66788C25.0954 1.28648 25.0954 0.668126 24.714 0.286779C24.3326 -0.0945677 23.7142 -0.0946165 23.3329 0.286779L12.5 11.1197L1.66715 0.286779C1.28575 -0.0946165 0.667394 -0.0946165 0.286047 0.286779C-0.0953001 0.668175 -0.0953489 1.28653 0.286047 1.66788L11.1189 12.5007L0.286047 23.3336C-0.0953489 23.715 -0.0953489 24.3334 0.286047 24.7147C0.47672 24.9054 0.726671 25.0007 0.976622 25.0007C1.22657 25.0007 1.47647 24.9054 1.6672 24.7147L12.5 13.8819L23.3328 24.7147C23.5235 24.9054 23.7734 25.0007 24.0234 25.0007C24.2733 25.0007 24.5233 24.9054 24.714 24.7147C25.0954 24.3333 25.0954 23.715 24.714 23.3336L13.8811 12.5008Z' fill='%23333333' fill-opacity='0.22'/%3E%3C/svg%3E%0A");
    width: 25px;
    height: 25px;
}
.modal-close:hover {
    color: black;
}

.modal-close:focus {
    color: black;
    outline: 0px;
}
