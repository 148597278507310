.button-container {
    width: fit-content;
    height: 45px;
    background: #4D69FF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 19px;
    cursor: pointer !important;
}

.button-container.invert {
    width: fit-content;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #4D69FF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
    cursor: pointer !important;
}

.button-container:hover {
    box-shadow: 0px 0px 20px rgba(96, 165, 230, 0.5);
}

.button-title {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
}
.button-container.invert .button-title {
    color:  #4D69FF;
}

.button-plus-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0C3.58853 0 0 3.58853 0 8C0 12.4115 3.58853 16 8 16C12.4115 16 16 12.4108 16 8C16 3.58916 12.4115 0 8 0ZM8 14.7607C4.27266 14.7607 1.23934 11.728 1.23934 8C1.23934 4.27203 4.27266 1.23934 8 1.23934C11.7273 1.23934 14.7607 4.27203 14.7607 8C14.7607 11.728 11.728 14.7607 8 14.7607Z' fill='white'/%3E%3Cpath d='M11.0996 7.32456H8.62095V4.84588C8.62095 4.50381 8.34395 4.22619 8.00127 4.22619C7.65858 4.22619 7.38158 4.50381 7.38158 4.84588V7.32456H4.90289C4.5602 7.32456 4.2832 7.60219 4.2832 7.94425C4.2832 8.28631 4.5602 8.56394 4.90289 8.56394H7.38158V11.0426C7.38158 11.3847 7.65858 11.6623 8.00127 11.6623C8.34395 11.6623 8.62095 11.3847 8.62095 11.0426V8.56394H11.0996C11.4423 8.56394 11.7193 8.28631 11.7193 7.94425C11.7193 7.60219 11.4423 7.32456 11.0996 7.32456Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.button-container.full-widgth {
    width: 100%;
}
.button-container.full-widgth .button-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 20px;
}
