
.card-block {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(96, 165, 230, 0.05);
    border-radius: 15px;
    padding: 14px 25px;
    margin: 10px;
    position: relative;
}

.card-block.clickable {
    cursor: pointer;
}

.card-block.clickable:hover {
    box-shadow: 0px 0px 20px rgba(96, 165, 230, 0.25);
}

.card-block .title {
    font-weight: bold;
    font-size: 35px;
    line-height: 35px;
    margin-top: 14px;
}
.card-block.custom .title {
    color: #607bf2;
}

.card-block .sub-title {
    margin-top: 14px;
    color: #606064;
    font-size: 20px;
    font-weight: bold;
}

.card-block.positive .sub-title {
    color: #62d7a5;
}

.card-block.negative .sub-title {
    color: #ff5050;
}


.card-block .pre-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #A9A9A9;
    min-height: 20px;
}

.card-block .baloon-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.baloon-container .baloon {
    display: flex;
    padding: 6px;
    background: rgba(0, 219, 161, 0.1);
    border-radius: 30px;
    min-width: 70px;
    align-items: center;
    justify-content: center;
    color: #62d7a5;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-left: 14px;
}

.card-normal {  
    width: 350px;
    height: 150px;
}

.card-big {  
    width: 600px;
    height: 250px;
}

.card-flex {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 300px;
    min-height: 150px;
}

.card-flex.big {
    flex-grow: 2;
}

.card-block.card-extended {
    height: 250px;
    min-width: 365px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}

.card-block.card-extended .card-body {
    display: flex;
    width: 100%;    
    padding: 30px 30px 20px 30px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: space-between;
}

.card-block.card-extended .card-column {
    display: flex;
    width: 100%;    
    flex-direction: column;
}

.card-block.card-extended .sub-title {
    font-size: 23px;
    line-height: 28px;

}


.card-block.card-extended .card-body .card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-block.card-extended .card-body .card-row.header {
    justify-content: space-between;
}

.card-block.card-extended .card-body .baloon { 
    display: flex;
    padding: 2px 10px;
    background: #637CFF;
    border-radius: 100px;
    min-width: 44px;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 23px;
}

.card-block.card-extended .card-footer {
    display: flex;
    flex-direction: column;
    background: #62d7a5;
    height: 69px;
    width: 100%;
    border-radius: 0 0 15px 15px;
    padding: 6px 30px;
}
.card-block.card-extended .card-row {
    display: flex;
}
.card-block .card-footer .sub-title {
    color: #ffffff;
    align-items: center;
}
.card-block.card-extended .card-footer.negative {
    background: #ff5050;
}

.card-block.card-extended .card-footer .pre-title {
    /* font-size: 18px;
    line-height: 20px; */
    color: #FFFFFF;
    /* min-width: 80px; */
}

.card-block.card-extended .card-footer .baloon {
    display: flex;
    padding: 7px 8px;
    background: #FFFFFF;
    border-radius: 30px;
    min-width: 84px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;        
    color: #62d7a5;   
    margin-left: 24px;
}

.card-block.card-extended .card-footer.negative .baloon {
    color: #ff5050; 
}

.card-block.card-extended .title { 
    font-size: 23px;
    line-height: 23px;
    margin-top: 0;
}

.card-block.card-extended .pre-title { 
    /* font-size: 18px; */
    /* color: rgba(51, 51, 51, 0.8); */
}

.card-block.card-extended  .sub-title{
    margin-top: 0;
}

.sum-symbol {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0699 3.20664V1.82902H3.11541L8.38453 7.49621C8.7144 7.8514 8.70994 8.40352 8.37399 8.75273L3.16431 14.1711H12.0697V13.339C12.0697 12.8349 12.4798 12.4248 12.9841 12.4248C13.4882 12.4248 13.8988 12.8349 13.8988 13.339V15.0853C13.8988 15.5896 13.4884 16 12.9841 16H1.01639C0.649405 16 0.318936 15.7818 0.174966 15.4437C0.0309292 15.1058 0.102634 14.7164 0.357003 14.4516L6.45631 8.10841L0.346561 1.53721C0.0989988 1.27062 0.0327796 0.883016 0.178006 0.549244C0.323397 0.215603 0.65261 -5.72205e-06 1.01639 -5.72205e-06H12.9844C13.4884 -5.72205e-06 13.899 0.410163 13.899 0.914639V3.20664C13.899 3.71085 13.4886 4.12118 12.9844 4.12118C12.48 4.12118 12.0699 3.71105 12.0699 3.20664Z' fill='%23333333' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
    width: 14px;
    height: 16px;
}

.m-symbol {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.5 0C14.7761 0 15 0.223858 15 0.5V15.5C15 15.7761 14.7761 16 14.5 16H13.3931C13.117 16 12.8931 15.7761 12.8931 15.5V5.29665C12.8931 4.78997 12.2262 4.60466 11.9648 5.03872L7.71979 12.0879C7.67577 12.161 7.59668 12.2057 7.51136 12.2057C7.42613 12.2057 7.34712 12.1611 7.30307 12.0882L3.0578 5.05703C2.79606 4.62354 2.12977 4.80909 2.12977 5.31547V15.5C2.12977 15.7761 1.90591 16 1.62977 16H0.5C0.223858 16 0 15.7761 0 15.5V0.5C0 0.223858 0.223858 0 0.5 0H2.12242C2.29755 0 2.45991 0.0916254 2.55044 0.241541L7.08247 7.74674C7.27717 8.06917 7.74496 8.0687 7.93902 7.74589L12.4497 0.242392C12.5401 0.0919937 12.7028 0 12.8782 0H14.5Z' fill='%23333333' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
    width: 15px;
    height: 16px;
}

.double-dot-symbol {
    background-image: url("data:image/svg+xml,%3Csvg width='3' height='11' viewBox='0 0 3 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.3 2.6C0.94 2.6 0.633333 2.47333 0.38 2.22C0.126667 1.96667 0 1.66 0 1.3C0 0.94 0.126667 0.633333 0.38 0.38C0.633333 0.126667 0.94 0 1.3 0C1.67333 0 1.98 0.126667 2.22 0.38C2.47333 0.633333 2.6 0.94 2.6 1.3C2.6 1.66 2.47333 1.96667 2.22 2.22C1.98 2.47333 1.67333 2.6 1.3 2.6ZM1.3 10.46C0.94 10.46 0.633333 10.3333 0.38 10.08C0.126667 9.82667 0 9.52 0 9.16C0 8.8 0.126667 8.49333 0.38 8.24C0.633333 7.98667 0.94 7.86 1.3 7.86C1.67333 7.86 1.98 7.98667 2.22 8.24C2.47333 8.49333 2.6 8.8 2.6 9.16C2.6 9.52 2.47333 9.82667 2.22 10.08C1.98 10.3333 1.67333 10.46 1.3 10.46Z' fill='%23333333' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
    width: 3px;
    height: 11px;
    margin: 4px 8px;
}

.double-dot-white-symbol {
    background-image: url("data:image/svg+xml,%3Csvg width='3' height='11' viewBox='0 0 3 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.3 2.6C0.94 2.6 0.633333 2.47333 0.38 2.22C0.126667 1.96667 0 1.66 0 1.3C0 0.94 0.126667 0.633333 0.38 0.38C0.633333 0.126667 0.94 0 1.3 0C1.67333 0 1.98 0.126667 2.22 0.38C2.47333 0.633333 2.6 0.94 2.6 1.3C2.6 1.66 2.47333 1.96667 2.22 2.22C1.98 2.47333 1.67333 2.6 1.3 2.6ZM1.3 10.46C0.94 10.46 0.633333 10.3333 0.38 10.08C0.126667 9.82667 0 9.52 0 9.16C0 8.8 0.126667 8.49333 0.38 8.24C0.633333 7.98667 0.94 7.86 1.3 7.86C1.67333 7.86 1.98 7.98667 2.22 8.24C2.47333 8.49333 2.6 8.8 2.6 9.16C2.6 9.52 2.47333 9.82667 2.22 10.08C1.98 10.3333 1.67333 10.46 1.3 10.46Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 3px;
    height: 11px;
    margin: 4px 8px;
}
.arrow-symbol {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.70711 0.292892C8.31658 -0.0976314 7.68342 -0.0976315 7.29289 0.292892L0.928933 6.65685C0.538408 7.04738 0.538408 7.68054 0.928933 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292892ZM9 17L9 1L7 1L7 17L9 17Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 16px;
    height: 17px;
}

.arrow-symbol.negative {
    transform: rotate(180deg);
}

.card-graph {
    width: 100%;
    height: 100%;
    position: absolute;
}

.card-graph .empty.blue {
    background-image: url("data:image/svg+xml,%3Csvg width='257' height='102' viewBox='0 0 257 102' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M59.5 74.4294C49.1 69.6294 16.1667 76.4294 1 80.4294V101.429H256.5V3.92947C257.5 2.09613 257.6 -0.470533 250 3.92947C240.5 9.42947 238 5.42947 228 31.9295C218 58.4295 205 52.4294 178.5 50.9294C152 49.4294 138 54.4294 118.5 74.4294C99 94.4295 72.5 80.4294 59.5 74.4294Z' fill='%23EEF1FF'/%3E%3Cpath d='M1 80C16.1667 76 49.1 69.2 59.5 74C72.5 80 99 94 118.5 74C138 53.9999 152 48.9999 178.5 50.4999C205 51.9999 218.891 58.3195 228 31.5C237 5 246.5 6.5 256 1' stroke='%234D69FF'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: cover;
    width: 88%;
    height: 80%;
}
