.input-container {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.input-container.big {
  flex-grow: 3;
}

.input-column:first-child {
  flex-grow: 1;
  margin-right: 40px;
  min-width: 60%;
}

.input-column {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 100px;
  min-width: 27%;
}

.input-container .input-title {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: #aaa9a9;
}

.input-container .input-content {
  display: flex;
  max-width: 182px;
}

.input-container.big .input-content {
  max-width: 100%;
}
.input-container .input-content > input,
.input-container .input-content > div {
  display: flex;
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 15px;
  min-width: 180px;
  height: 40px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 2px;
}

.input-container .input-content > input.big,
.input-container .input-content > div.big {
  flex-grow: 3;
}
