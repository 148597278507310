.sidebar-menu-content .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  background: #778df7;
  user-select: none;
  width: 4px;
  height: calc(100% - 20px);
  top: 10px;
  right: 0px;
}

.sidebar-menu-content .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  cursor: pointer;
  border-radius: 4px;
  background: #3c55d8;
  width: 100%;
  height: 0px;
}

.sidebar-menu-content .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  background: #778df7;
  user-select: none;
  height: 4px;
  width: 100%;
  bottom: 0px;
  left: 0;
}
.sidebar-menu-content .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
  cursor: pointer;
  border-radius: 4px;
  background: #3c55d8;
  height: 100%;
}

.sidebar-menu-one .sidebar-menu-content .nav-sidebar-menu > .nav-item.sub-menu > .nav-link.deactivate span {
  color: #bdbbbb;
}
